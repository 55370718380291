import { CalendarToday } from "@mui/icons-material";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import ScienceIcon from "@mui/icons-material/Science";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Person4Icon from "@mui/icons-material/Person4";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import SafetyDividerIcon from "@mui/icons-material/SafetyDivider";

export const DOCTORS = [
  {
    title: "Travis Hernandez",
    specialization: "Oncologist",
    availableDate: "2022-02-06",
  },
  {
    title: "Christine Becker",
    specialization: "Pediatrician",
    availableDate: "2022-07-01",
  },
  {
    title: "Marie Gonzalez",
    specialization: "Plastic surgery",
    availableDate: "2022-12-02",
  },
  {
    title: "Tiffany Howard",
    specialization: "Surgent",
    availableDate: "2022-01-12",
  },
  {
    title: "Christopher Thompson",
    specialization: "Pediatrician",
    availableDate: "2022-03-05",
  },
  {
    title: "Victoria Bailey",
    specialization: "Surgent",
    availableDate: "2022-01-27",
  },
  {
    title: "Kaitlyn Dawson",
    specialization: "Oncology",
    availableDate: "2022-04-18",
  },
  {
    title: "Dana Floyd",
    specialization: "Surgent",
    availableDate: "2022-03-28",
  },
  {
    title: "Latoya Mckinney",
    specialization: "Pediatrician",
    availableDate: "2022-09-20",
  },
  {
    title: "Jesus Williams",
    specialization: "Psychiatrist",
    availableDate: "2022-04-16",
  },
  {
    title: "Timothy Brooks",
    specialization: "Surgent",
    availableDate: "2022-12-10",
  },
  {
    title: "Karen Clark",
    specialization: "Surgent",
    availableDate: "2022-12-28",
  },
  {
    title: "Jonathan Lowe",
    specialization: "Nurologist",
    availableDate: "2022-09-06",
  },
  {
    title: "William Dunn",
    specialization: "Oncologist",
    availableDate: "2022-06-11",
  },
  {
    title: "Michelle Steele",
    specialization: "Nurologist",
    availableDate: "2022-02-01",
  },
  {
    title: "Savannah Hoffman",
    specialization: "Surgent",
    availableDate: "2022-07-07",
  },
  {
    title: "Jamie Stewart",
    specialization: "Surgent",
    availableDate: "2022-12-28",
  },
  {
    title: "Benjamin Dunn",
    specialization: "Nurologist",
    availableDate: "2022-09-15",
  },
  {
    title: "James French",
    specialization: "Pediatrician",
    availableDate: "2022-03-26",
  },
  {
    title: "Heather George",
    specialization: "Surgent",
    availableDate: "2022-04-20",
  },
  {
    title: "Crystal Davis",
    specialization: "Surgent",
    availableDate: "2022-04-09",
  },
  {
    title: "George Kline",
    specialization: "Surgent",
    availableDate: "2022-10-14",
  },
  {
    title: "Krista Martinez",
    specialization: "Ophthalmology",
    availableDate: "2022-08-28",
  },
  {
    title: "Andrew Thompson",
    specialization: "Ophthalmologist",
    availableDate: "2022-08-24",
  },
  {
    title: "Eric Russell",
    specialization: "Cardiologist",
    availableDate: "2022-12-24",
  },
  {
    title: "Nancy Webb",
    specialization: "Ophthalmology",
    availableDate: "2022-10-07",
  },
  {
    title: "Edward Howard",
    specialization: "Cardiologist",
    availableDate: "2022-04-13",
  },
  {
    title: "Carlos Nelson",
    specialization: "Plastic surgery",
    availableDate: "2022-12-14",
  },
  {
    title: "Courtney Cardenas",
    specialization: "Ophthalmology",
    availableDate: "2022-10-01",
  },
  {
    title: "Keith Torres",
    specialization: "Oncologist",
    availableDate: "2022-06-09",
  },
  {
    title: "Jonathan Huynh",
    specialization: "Ophthalmology",
    availableDate: "2022-06-05",
  },
  {
    title: "Willie Hill",
    specialization: "Plastic surgery",
    availableDate: "2022-06-20",
  },
  {
    title: "Heather Peterson",
    specialization: "Nurologist",
    availableDate: "2022-09-24",
  },
  {
    title: "Zachary Henderson",
    specialization: "Surgent",
    availableDate: "2022-07-01",
  },
  {
    title: "Charles Hodges",
    specialization: "Psychiatrist",
    availableDate: "2022-05-01",
  },
  {
    title: "Gregory Mann",
    specialization: "Plastic surgery",
    availableDate: "2022-05-18",
  },
  {
    title: "Jenna Rosales",
    specialization: "Surgent",
    availableDate: "2022-01-22",
  },
  {
    title: "Albert Price",
    specialization: "Nurologist",
    availableDate: "2022-02-10",
  },
  {
    title: "Tina Gonzalez",
    specialization: "Oncology",
    availableDate: "2022-07-15",
  },
  {
    title: "Kyle Boone",
    specialization: "Ophthalmology",
    availableDate: "2022-06-21",
  },
  {
    title: "Kevin Richmond",
    specialization: "Oncology",
    availableDate: "2022-02-26",
  },
  {
    title: "Connie Smith",
    specialization: "Plastic surgery",
    availableDate: "2022-01-04",
  },
  {
    title: "Ashley King",
    specialization: "Psychiatrist",
    availableDate: "2022-05-25",
  },
  {
    title: "Patricia Steele",
    specialization: "Ophthalmology",
    availableDate: "2022-06-05",
  },
  {
    title: "Scott Osborne",
    specialization: "Cardiologist",
    availableDate: "2022-09-19",
  },
  {
    title: "Shane Chandler",
    specialization: "Pediatrician",
    availableDate: "2022-07-15",
  },
  {
    title: "Michael Rios",
    specialization: "Nurologist",
    availableDate: "2022-12-05",
  },
  {
    title: "Michael Simpson",
    specialization: "Pediatrician",
    availableDate: "2022-07-09",
  },
  {
    title: "Kevin Larson",
    specialization: "Psychiatrist",
    availableDate: "2022-03-13",
  },
  {
    title: "Joe Smith",
    specialization: "Psychiatrist",
    availableDate: "2022-05-04",
  },
  {
    title: "Ryan Kline",
    specialization: "Plastic surgery",
    availableDate: "2022-04-09",
  },
  {
    title: "James Hoover",
    specialization: "Surgent",
    availableDate: "2022-04-14",
  },
  {
    title: "Donna Walsh",
    specialization: "Oncology",
    availableDate: "2022-09-18",
  },
  {
    title: "Dave Jones",
    specialization: "Nurologist",
    availableDate: "2022-05-12",
  },
  {
    title: "Linda Dudley",
    specialization: "Ophthalmology",
    availableDate: "2022-04-04",
  },
  {
    title: "Justin Vega",
    specialization: "Plastic surgery",
    availableDate: "2022-01-06",
  },
  {
    title: "Jennifer Anderson",
    specialization: "Surgent",
    availableDate: "2022-07-05",
  },
  {
    title: "Karen Hensley",
    specialization: "Pediatrician",
    availableDate: "2022-12-27",
  },
  {
    title: "Thomas Foster",
    specialization: "Pediatrician",
    availableDate: "2022-01-17",
  },
  {
    title: "Kevin Cooper",
    specialization: "Ophthalmology",
    availableDate: "2022-02-06",
  },
  {
    title: "Amy Miles",
    specialization: "Oncology",
    availableDate: "2022-03-12",
  },
  {
    title: "Kimberly Davis",
    specialization: "Oncologist",
    availableDate: "2022-01-06",
  },
  {
    title: "Donald Middleton",
    specialization: "Cardiologist",
    availableDate: "2022-10-20",
  },
  {
    title: "Kimberly Dean",
    specialization: "Cardiologist",
    availableDate: "2022-12-21",
  },
  {
    title: "Edwin Benjamin",
    specialization: "Surgent",
    availableDate: "2022-02-22",
  },
  {
    title: "Perry Park",
    specialization: "Nurologist",
    availableDate: "2022-01-21",
  },
  {
    title: "Roberto Ramos",
    specialization: "Plastic surgery",
    availableDate: "2022-09-07",
  },
  {
    title: "Samuel Carr",
    specialization: "Ophthalmology",
    availableDate: "2022-07-20",
  },
  {
    title: "Ashley Miller",
    specialization: "Plastic surgery",
    availableDate: "2022-10-21",
  },
  {
    title: "Chelsea Maldonado",
    specialization: "Oncologist",
    availableDate: "2022-06-27",
  },
  {
    title: "Jeff Peterson",
    specialization: "Ophthalmologist",
    availableDate: "2022-01-04",
  },
  {
    title: "Julia Wyatt",
    specialization: "Oncology",
    availableDate: "2022-02-05",
  },
  {
    title: "Gregory Meyers",
    specialization: "Psychiatrist",
    availableDate: "2022-10-12",
  },
  {
    title: "Sharon Bell",
    specialization: "Psychiatrist",
    availableDate: "2022-09-27",
  },
  {
    title: "Shane Melton",
    specialization: "Cardiologist",
    availableDate: "2022-09-16",
  },
  {
    title: "Daniel Case",
    specialization: "Surgent",
    availableDate: "2022-09-01",
  },
  {
    title: "Shannon Mcneil",
    specialization: "Ophthalmologist",
    availableDate: "2022-02-07",
  },
  {
    title: "Christina Walter",
    specialization: "Ophthalmology",
    availableDate: "2022-10-06",
  },
  {
    title: "Eric Garrett",
    specialization: "Plastic surgery",
    availableDate: "2022-03-01",
  },
  {
    title: "Anthony Davis",
    specialization: "Plastic surgery",
    availableDate: "2022-06-26",
  },
  {
    title: "Ronald Davis",
    specialization: "Oncologist",
    availableDate: "2022-12-24",
  },
  {
    title: "James Martinez",
    specialization: "Cardiologist",
    availableDate: "2022-03-13",
  },
  {
    title: "Richard Carlson",
    specialization: "Ophthalmologist",
    availableDate: "2022-12-21",
  },
  {
    title: "Susan Russell",
    specialization: "Ophthalmologist",
    availableDate: "2022-11-16",
  },
  {
    title: "Janet May",
    specialization: "Plastic surgery",
    availableDate: "2022-03-20",
  },
  {
    title: "Tony Huang",
    specialization: "Ophthalmology",
    availableDate: "2022-06-03",
  },
  {
    title: "Stacey Adams",
    specialization: "Nurologist",
    availableDate: "2022-12-13",
  },
  {
    title: "Cynthia Reyes",
    specialization: "Pediatrician",
    availableDate: "2022-09-11",
  },
  {
    title: "Margaret Hanna",
    specialization: "Oncology",
    availableDate: "2022-12-09",
  },
  {
    title: "Sarah Blackwell",
    specialization: "Ophthalmologist",
    availableDate: "2022-09-05",
  },
  {
    title: "Paul Thompson",
    specialization: "Ophthalmologist",
    availableDate: "2022-07-26",
  },
  {
    title: "Ann Smith",
    specialization: "Ophthalmology",
    availableDate: "2022-01-15",
  },
  {
    title: "Scott Roth",
    specialization: "Plastic surgery",
    availableDate: "2022-02-19",
  },
  {
    title: "Keith Fowler",
    specialization: "Cardiologist",
    availableDate: "2022-01-27",
  },
  {
    title: "Gregory Scott",
    specialization: "Ophthalmology",
    availableDate: "2022-03-04",
  },
  {
    title: "Jennifer Taylor",
    specialization: "Pediatrician",
    availableDate: "2022-06-27",
  },
  {
    title: "Teresa Wright",
    specialization: "Ophthalmology",
    availableDate: "2022-03-17",
  },
  {
    title: "Ricardo Francis",
    specialization: "Pediatrician",
    availableDate: "2022-08-27",
  },
  {
    title: "Beth Ingram",
    specialization: "Nurologist",
    availableDate: "2022-07-27",
  },
];

export const SPECIALITIES = [
  { title: "Surgent" },
  { title: "Psychiatrist" },
  { title: "Oncology" },
  { title: "Pediatrician" },
  { title: "Ophthalmology" },
  { title: "Plastic surgery" },
  { title: "Ophthalmologist" },
  { title: "Cardiologist" },
  { title: "Oncologist" },
];

export const FAQ = [
  {
    question: "Can I use any smartphone? ",
    answer:
      "You can use any smartphone with an internet connection, mobile or Wi-Fi internet connection to access service.",
  },
  {
    question: "Do I need to register for the service? ",
    answer:
      "No. to make an appointment but you need to register before the consultation so the doctor will need to know your details such as name, age, and gender.  ",
  },
  {
    question: "How do I make a payment? ",
    answer:
      "You will need to pay via Visa or MasterCard for a virtual consultation. ",
  },
  {
    question: "Do I need to install any applications? ",
    answer:
      "No. You do not need any application to download and install. Video call will be loaded in your internet browser application. Please allow microphone and camera access to the browser to enable video calls for the doctor consultation. ",
  },
  {
    question: "The video is too dark. What should I do? ",
    answer:
      "Please move to a room with sufficient light and a light source is best behind your smartphone directed towards you. ",
  },
  {
    question: "How do I join computer/device audio? ",
    answer:
      "On most devices, you can join computer/device audio by clicking Join Audio, Join with Computer Audio, or Audio to access the audio settings. Please allow the browser to access to camera and the microphone. If you have any further issues with connecting with voice and video, please refer to the phone settings and user manual of your phone or computer. ",
  },
  {
    question: "Can I record my consultation? ",
    answer:
      "No. For confidentiality reasons, we do not allow anyone to record the consultation but we do not control applications on your devices and any application may record without our knowledge.  ",
  },
  {
    question: "There is an echo in my meeting. ",
    answer:
      "Echo can be caused by many things, such as a participant connected to the meeting audio on multiple devices or two participants joining in from the same location. ",
  },
];

export const TERMS_AND_CONDITIONS = [
  {
    title: "",
    content:
      "MEDICA integrates with your doctor's patient management and information system to better manage your health. By using MEDICA, you will be able to:",
    pointCotent: [],
  },
  {
    title: "Integration with Your Doctor's System:",
    content:
      "MEDICA syncs with your doctor's records, making your health information accessible. You can also connect with trusted third-party apps to enhance your experience.",
    pointCotent: [],
  },
  {
    title: "Information Disclaimer: ",
    content:
      "Information in MEDICA is for personal use. We don't guarantee its accuracy. We'll update the app and terms as needed.",
    pointCotent: [],
  },
  {
    title: "Account Deletion:",
    content:
      "You can delete your MEDICA account at any time without affecting your doctor's records.",
    pointCotent: [],
  },
  {
    title: "Terms of Service Agreement for MEDICA",
    content:
      "Welcome to MEDICA. Please read these Terms of Service carefully before using our website. By using our services, you agree to abide by the terms outlined in this agreement.",
    pointCotent: [],
  },
  {
    title: "Acceptance of Terms",
    content:
      "By accessing or using our website, you acknowledge that you have read, understood, and agree to comply with these Terms of Service. If you do not agree with these terms, please do not use our website.",
    pointCotent: [],
  },
  {
    title: "Changes to the Terms",
    content:
      "We may update and modify these terms at any time. Any changes will be effective immediately upon posting. It is your responsibility to review these terms regularly to stay informed of any updates.",
    pointCotent: [],
  },
  {
    title: "Medical Services",
    content: `Our website provides information about medical services and the ability to schedule appointments with healthcare providers. The information provided is for general informational purposes only and should not be considered a substitute for professional medical advice.`,
    pointCotent: [],
  },

  {
    title: "User Responsibilities",
    content: `By using our website, you agree:`,
    pointCotent: [
      {
        title: ``,
        points: [
          `To provide accurate and current information when scheduling appointments.`,
          `Not to impersonate others or provide false information`,
          `Not to use our website for any unlawful or unauthorized purpose.`,
          `To comply with all applicable laws and regulations.`,
        ],
      },
    ],
  },
  {
    title: "Privacy",
    content:
      "We respect your privacy and handle your information as outlined in our Privacy Statement. Please review our Privacy Statement for more information.",
    pointCotent: [],
  },
  {
    title: "Intellectual Property",
    content:
      "All content and materials on our website, including text, graphics, logos, and images, are protected by copyright and other intellectual property rights. You may not use, reproduce, or distribute these materials without our explicit consent.",
    pointCotent: [],
  },
  {
    title: "Termination",
    content:
      "We reserve the right to suspend, restrict, or terminate your access to our website at our sole discretion, with or without notice, if you violate these terms or engage in prohibited activities.",
    pointCotent: [],
  },
  {
    title: "Disclaimers and Limitations",
    content: "",
    pointCotent: [
      {
        title: ``,
        points: [
          `We make no warranties or representations about the accuracy or completeness of the information on our website.`,
          `We are not responsible for any errors or omissions on the website.`,
          `We disclaim all liability for any damages or injuries resulting from your use of our services.`,
        ],
      },
    ],
  },

  {
    title: "Indemnification",
    content:
      "You agree to indemnify and hold us harmless from any claims, damages, losses, or expenses resulting from your use of our website, violation of these terms, or infringement of any rights.",
    pointCotent: [],
  },
  {
    title: "Governing Law and Jurisdiction",
    content:
      "These terms are governed by the laws of Bangladesh. Any disputes arising from these terms or your use of our website will be subject to the exclusive jurisdiction of the courts in Bangladesh.",
    pointCotent: [],
  },
];

export const WHAT_WE_OFFER = [
  {
    icon: (
      <EventAvailableIcon
        sx={{
          mt: 0.5,
          fontSize: 20,
        }}
      />
    ),
    title: "Online Appointment ",
    content: `Book your doctor's appointment online for video consultation (Virtual)
              from anywhere or at the dispensary (Physical).`,
  },
  {
    icon: (
      <SafetyDividerIcon
        sx={{
          mt: 0.5,
          fontSize: 20,
        }}
      />
    ),
    title: "24/7 Consultation",
    content: `Consult a doctor anytime from anywhere virtually or at your nearest dispensary`,
  },
  {
    icon: (
      <ScienceIcon
        sx={{
          mt: 0.5,
          fontSize: 20,
        }}
      />
    ),
    title: "Laboratory Services",
    content: `Full laboratory services, sample collection from home, and results emailed to you and/or sent to directly.`,
  },
  {
    icon: (
      <LocalShippingIcon
        sx={{
          mt: 0.5,
          fontSize: 20,
        }}
      />
    ),
    title: "Medicine Delivery",
    content: `Get your medication delivered right to your doorstep.`,
  },
  {
    icon: (
      <HomeWorkIcon
        sx={{
          mt: 0.5,
          fontSize: 20,
        }}
      />
    ),
    title: "Home Admissions",
    content: `Doctor consultation, Nursing at home. We manage your complete health care needs at home with qualified Nurses, Physiotherapists, and attendants.`,
  },
];

export const ONGOING_APPOINTMENTS = [
  {
    id: 1,
    dateTime: "2021-08-01T10:00:00.000Z",
    status: "pending",
    type: "video",
    doctor: "Dr. Nimal Perera",
  },
  {
    id: 2,
    dateTime: "2021-08-01T10:00:00.000Z",
    status: "pending",
    type: "video",
    doctor: "Dr. Nimal Perera",
  },
  {
    id: 3,
    dateTime: "2021-08-01T10:00:00.000Z",
    status: "pending",
    type: "video",
    doctor: "Dr. Nimal Perera",
  },
  {
    id: 4,
    dateTime: "2021-08-01T10:00:00.000Z",
    status: "pending",
    type: "video",
    doctor: "Dr. Nimal Perera",
  },
];
