import { Close, Search } from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppointments } from "../../app/apiManager/appointmentService";
import logo from "../../assets/logo.png";
import medicaLogo from "../../assets/medica.png";
import AppointmentCard from "../../components/AppointmentCard/AppointmentCard";
import CustomModal from "../../components/CustomModal/CustomModal";
import { APPOINTMENT_STATUS } from "../../const";
import { useNavigate } from "react-router-dom";

const ViewAppointments = () => {
  const [open, setOpen] = useState(true);
  const [appointmentType, setAppointmentType] = useState("PENDING");
  const [appointmentList, setAppointmentList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const mobile = useSelector((state) => state.signIn.Mobile);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("activeStep", "fetching appointments");
    let phoneNumber = mobile;

    getAppointments(
      phoneNumber,
      (data) => {
        if (data.Status === 1000) {
          setAppointmentList(data.Data);
        }
      },
      () => {}
    );
  }, [mobile]);

  const handleChangeAppointmentType = (event, newValue) => {
    if (newValue) {
      setAppointmentType(newValue);
    }
  };

  const pendingAppointmentList = appointmentList.filter(
    (oneEl) =>
      oneEl?.DoctorName?.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (oneEl?.ChannelingStatus === APPOINTMENT_STATUS.PENDING ||
        oneEl.ChannelingStatus === APPOINTMENT_STATUS.ONGOING) &&
      new Date(oneEl?.TimeStart) > new Date()
  );

  return (
    <CustomModal
      open={open}
      sx={{
        p: 3,
        py: 2,
        width: { xs: "90%", sm: 450, md: 650 },
      }}
    >
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography
          sx={{
            fontSize: "1.2rem",
            fontweight: "bold",
          }}
        >
          Your Appointments
        </Typography>
        <IconButton
          onClick={() => {
            setOpen(false);
            navigate("/");
          }}
        >
          <Close />
        </IconButton>
      </Box>

      <Grid item xs={12} sm lg>
        <>
          {/* <Box
              display={"flex"}
              alignItems="center"
              justifyContent={"center"}
              gap={2}
              mt={1}
              mb={1}
            > */}
          <Grid container display={"flex"} alignItems="center" mb={1} mt={1}>
            <Grid item xs sm md lg>
              <Input
                fullWidth
                placeholder="Search appointments by doctor "
                inputProps={{ variant: "outlined" }}
                onChange={(event) => {
                  setSearchQuery(event.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              sx={{
                mt: {
                  xs: 1,
                  sm: 1,
                  md: 0,
                },
              }}
            >
              <ToggleButtonGroup
                color="primary"
                value={appointmentType}
                exclusive
                onChange={handleChangeAppointmentType}
                size="small"
                sx={{
                  display: "flex",
                }}
              >
                <ToggleButton
                  sx={{
                    flexGrow: 1,
                  }}
                  value="PENDING"
                >
                  Pending
                </ToggleButton>
                <ToggleButton
                  sx={{
                    flexGrow: 1.5,
                  }}
                  value="PAST"
                >
                  Past
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>

          <Box
            sx={{
              height: {
                xs: "30rem",
                md: "19rem",
              },
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "0.4em",
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0,0,0,.1)",
                outline: "1px solid slategrey",
              },
              "&": {
                scrollbarWidth: "thin",
              },
              pr: 1,
            }}
          >
            <Grid container overflow={"auto"} spacing={1}>
              {appointmentType === "PENDING"
                ? appointmentList
                    .filter(
                      (oneEl) =>
                        oneEl?.DoctorName?.toLowerCase().includes(
                          searchQuery.toLowerCase()
                        ) &&
                        (oneEl?.ChannelingStatus ===
                          APPOINTMENT_STATUS.PENDING ||
                          oneEl.ChannelingStatus ===
                            APPOINTMENT_STATUS.ONGOING) &&
                        new Date(oneEl?.TimeEnd) > new Date()
                    )
                    .map((oneEl, index) => {
                      return (
                        <Grid key={index} item xs={12} sm={6} lg={6}>
                          <AppointmentCard
                            appointmentId={oneEl.Id}
                            doctorName={oneEl.DoctorName}
                            dateTime={oneEl.TimeStart}
                            channelingStatus={oneEl.ChannelingStatus}
                          />
                        </Grid>
                      );
                    })
                : appointmentList
                    .filter((oneEl) =>
                      oneEl.DoctorName.toLowerCase().includes(
                        searchQuery.toLowerCase()
                      )
                    )
                    .map((oneEl, index) => (
                      <Grid key={index} item xs={12} sm={6} lg={6}>
                        <AppointmentCard
                          appointmentId={oneEl.Id}
                          doctorName={oneEl.DoctorName}
                          dateTime={oneEl.TimeStart}
                          channelingStatus={oneEl.ChannelingStatus}
                        />
                      </Grid>
                    ))}

              {(appointmentType === "PENDING" && pendingAppointmentList) ||
              appointmentList.length === 0 ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    height: {
                      xs: "18rem",
                      md: "18rem",
                    },
                  }}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  No Appointments Yet!
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Box>
        </>
      </Grid>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        mt={2}
        gap={0.5}
      >
        <img src={logo} alt="docnote logo" width={"100px"} />
        {/* <Divider
          orientation="vertical"
          sx={{
            mt: 0.5,
            width: "2px",
            height: "25px",
            backgroundColor: "#ABABAB",
          }}
        />
        <img src={medicaLogo} alt="medica logo" width={"120px"} style={{}} /> */}
      </Box>
    </CustomModal>
  );
};
export default ViewAppointments;
