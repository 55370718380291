import { Close, Done } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import React, { useEffect, useState } from "react";
import { sessionStatus } from "../../app/apiManager/channelingStatusService";
import Dot from "../../components/Dot/Dot";
import PatientsDot from "../../components/PatientsDot/PatientsDot";
import { APPOINTMENT_STATUS } from "../../const";

const StepThree = ({
  setActiveStep,
  appointment,
  setAppointmentCompleted,
  setAppointmentCancelled,
}) => {
  // const arr = Array(100).fill(45);
  const [firstLoad, setFirstLoad] = useState(true);
  const [current, setCurrent] = useState(0);
  const [myPosition, setMyPosition] = useState(0);
  const [session, setSession] = useState([]);
  const [appoinmentIn, setAppoinmentIn] = useState({
    text: "",
    color: "black",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setMyPosition(appointment.AppointmentNumber);
  }, [appointment]);

  const checkStatus = () => {
    setLoading(true);
    sessionStatus(
      appointment.SessionId,
      (response) => {
        if (response) {
          //FIND THE CURRENT BY LOOPING THROUGH THE RESPONSE
          let onGoingAppointment = null;
          for (let i = 0; i < response.length; i++) {
            //finding the ongoing appointment
            if (response[i].ChanalingStatus !== APPOINTMENT_STATUS.ONGOING) {
              continue;
            } else {
              onGoingAppointment = response[i].AppointmentNumber;
              setCurrent(response[i].AppointmentNumber);
            }
          }
          setSession(response);

          let appointmentStartTime = new Date(appointment.SessionStartTime);
          //if appointment is not started show the time left
          // if (new Date() > appointmentStartTime) {

          //filter the appointments and find the updated status for my appointment
          const myAppointment = response.filter(
            (oneElement) =>
              oneElement.AppointmentNumber === appointment.AppointmentNumber
          );
          if (myAppointment.length > 0) {
            if (
              myAppointment[0].ChanalingStatus === APPOINTMENT_STATUS.COMPLETED
            ) {
              setAppoinmentIn({
                text: "Your appointment is completed",
                color: "darkgreen",
              });
              return setTimeout(() => {
                setAppointmentCompleted(true);
                setActiveStep(4);
              }, 2000);
            } else {
              if (
                myAppointment[0].ChanalingStatus !==
                  APPOINTMENT_STATUS.PENDING &&
                myAppointment[0].ChanalingStatus !== APPOINTMENT_STATUS.ONGOING
              ) {
                //appointment is not completed but cancelled
                setAppoinmentIn({
                  text: "Your appointment was cancelled",
                  color: "red",
                });

                return setTimeout(() => {
                  setAppointmentCancelled(true);
                  setAppointmentCompleted(true);
                  setActiveStep(4);
                }, 2000);
              } else if (onGoingAppointment === appointment.AppointmentNumber) {
                setAppoinmentIn({
                  text: "Your appointment is ongoing",
                  color: "darkgreen",
                });
              } else {
                const timeToStart = new Date();

                //to calculate time to start we need keep only pending appointments
                //until the current appointment
                const pendingAppointments = response.filter(
                  (oneElement) =>
                    oneElement.ChanalingStatus === APPOINTMENT_STATUS.PENDING &&
                    oneElement.AppointmentNumber <=
                      appointment.AppointmentNumber
                );

                timeToStart.setMinutes(
                  timeToStart.getMinutes() + pendingAppointments.length * 10
                );

                if (
                  Array.isArray(pendingAppointments) &&
                  pendingAppointments.length > 0
                ) {
                  console.log(pendingAppointments[0], myAppointment);
                  if ((pendingAppointments[0].Id === myAppointment[0].Id)) {
                    setAppoinmentIn({
                      text: ` Doctor Arrived`,
                      color: "green",
                    });
                  } else {
                    setAppoinmentIn({
                      text: ` Your appointment ${formatDistanceToNow(
                        timeToStart,
                        {
                          addSuffix: true,
                        }
                      )}`,
                      color: "black",
                    });
                  }
                }
              }
            }
          }
        }
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    let timeout = null;
    if (firstLoad) {
      timeout = checkStatus();
      setFirstLoad(false);
    }
    const interval = setInterval(() => {
      timeout = checkStatus();
    }, 5000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {appoinmentIn.text.length > 0 && (
          <Typography
            fontWeight={"bold"}
            sx={{
              pb: { xs: 1, sm: 1.2 },
              fontSize: { xs: "1rem", sm: "1.5rem" },
              lineHeight: "1rem",
              my: 1,
              mr: 1,
              color: appoinmentIn.color,
            }}
          >
            {appoinmentIn.text}{" "}
          </Typography>
        )}
        {appoinmentIn.text.length === 0 && (
          <Typography
            fontWeight={"bold"}
            sx={{
              pb: { xs: 1, sm: 1.2 },
              fontSize: { xs: "1rem", sm: "1.5rem" },
              lineHeight: "1rem",
              my: 1,
              mr: 1,
            }}
          >
            Retriving your appointment status...
          </Typography>
        )}
        {loading && <CircularProgress size={17} />}
      </Box>

      <Box
        display={"flex"}
        flexWrap={"wrap"}
        my={3}
        alignContent="start"
        sx={{
          // minHeight: {
          //   xs: "24rem",
          //   lg: "13.7rem",
          // },
          height: {
            xs: "24rem",
            lg: "13.7rem",
          },
          overflowY: "auto",
          scrollbarWidth: "thin",
          px: {
            md: 0,
            xs: 2,
          },
        }}
      >
        {session.map((oneEl, index) => {
          //if cancelled
          let status = "pending";
          if (
            oneEl.ChanalingStatus ===
              APPOINTMENT_STATUS.CANCELLED_WITH_RESCHEDULED ||
            oneEl.ChanalingStatus === APPOINTMENT_STATUS.CANCELLED_AS_NO_SHOW ||
            oneEl.ChanalingStatus === APPOINTMENT_STATUS.CANCELLED_WITH_REFUND
          ) {
            status = "CANCELLED";
          }

          if (oneEl.ChanalingStatus === APPOINTMENT_STATUS.COMPLETED) {
            status = "COMPLETED";
            //if appointment number is equal to appointment number set all completed
            if (oneEl.AppointmentNumber === appointment.AppointmentNumber) {
              setAppointmentCompleted(true);
            }
          }
          return (
            <PatientsDot
              key={index}
              myPosition={myPosition}
              index={oneEl.AppointmentNumber}
              status={status}
              current={current}
            />
          );
        })}
      </Box>

      <Divider orientation="horizontal" sx={{ my: 1 }} />

      <Grid container mt={1} rowSpacing={2}>
        <Grid
          item
          xs
          display={"flex"}
          alignItems="center"
          justifyContent={"center"}
        >
          <Box display={"flex"} alignItems="center" gap={2}>
            <Done sx={{ color: "#27C064", fontSize: "1.5rem", zIndex: 2 }} />
            <Typography fontWeight={"bold"}>Completed</Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs
          display={"flex"}
          alignItems="center"
          justifyContent={"center"}
        >
          <Box display={"flex"} alignItems="center" gap={2}>
            <Close sx={{ color: "#F84646", fontSize: "1.5rem", zIndex: 2 }} />
            <Typography fontWeight={"bold"}>Cancelled</Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs
          mt={0.1}
          sx={{ mb: { xs: 1, sm: 0 } }}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box
            sx={{
              display: "flex",
              margin: "0.2rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box display={"flex"} alignItems="center" gap={1}>
              <Dot
                color={"#27C064"}
                sx={{ width: "0.9rem", height: "0.9rem" }}
              />
              Your Appointment
            </Box>
            <Typography
              sx={{
                color: "#727272",
                fontSize: "0.8rem",
                fontWeight: "bold",
                marginLeft: "0.5rem",
              }}
            >
              {myPosition === 0 ? "Now" : myPosition}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default StepThree;
