import styled from "@emotion/styled";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { order } from "../../app/apiManager/apiManager";
import { placeAppointment } from "../../app/apiManager/appointmentService";
import { sendNotification } from "../../app/apiManager/notificationServices";
import LinkText from "../../components/LinkText/LinkText";
import { PAYMENT_METHODS, PRODUCTION } from "../../const";
import {
  setAppointmentId,
  setPaymentDetails,
  setStep,
} from "../../reducers/appointmentInfoSlice";
import { formattedString } from "../../utils/formattedString";
import CustomModal from "../../components/CustomModal/CustomModal";
import ModalLoading from "./ModalLoading";
import PaymentSuccess from "./PaymentSuccess";
import PaymentFailed from "./PaymentFailed";
import ShowAlert from "./ShowAlert";
import getOriginalDateTime from "../../utils/formatAMPM";
import { convertToCurrency } from "../../utils/convert-to-currency";

const StepFour = ({
  setShowing,
  setSideMenuOpen,
  setPaymentStatus,
  doctorId,
  makeAppontment,
  isOtherCountry,
  doctor,
}) => {
  const navigate = useNavigate();
  const session = useSelector((state) => state.placeAppointment.session);

  const channelingFee = isOtherCountry
    ? parseInt(process.env.REACT_APP_CHANNELING_FEE_OTHER) +
      parseInt(process.env.REACT_APP_SERVICE_FEE_OTHER)
    : doctor.doctorFee + doctor.hospitalFee + doctor.otherFee;

  const sessionId = session?.sessionId;
  const loadingStatus = useSelector((state) => state.loadingStatus);
  const appointmentDateTime = session?.timeStart;
  const patientId = useSelector((state) => state.appointmentInfo.patientId);
  const patientName = useSelector((state) => state.appointmentInfo.patientName);
  const patientMobileNo = useSelector((state) => state.appointmentInfo.phoneNo);
  const patientEmail = useSelector(
    (state) => state.appointmentInfo.patientEmail
  );
  const sessionType = useSelector((state) => state.appointmentInfo.sessionType);

  const StyledTextField = styled(TextField)({
    ".MuiInput-underline": {
      marginTop: 4,
    },
  });
  const [promoCode, setPromoCode] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [paymentOption, setPaymentOption] = useState(PAYMENT_METHODS.PAY_NOW);
  const [isFree, setIsFree] = useState(false);
  // const [isFree, setIsFree] = useState( appointmentDateTime.toLocaleString('en-us', {year: 'numeric', month: '2-digit', day: '2-digit'}).
  // replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2')=="2023-04-05");

  const dispatch = useDispatch();

  const calculateEstimatedAppointmentStartTime = (dateTime) => {
    dateTime = new Date(dateTime);
    dateTime = dateTime.setMinutes(dateTime.getMinutes() + 15);
    return dateTime;
  };

  const conversionRatio = useSelector(
    (state) => state.conversionRatio.conversionRatio
  );

  useEffect(() => {
    // console.log(appointmentDateTime);
    if (isFree) {
      setShowPopup(true);
    }
  }, [isFree]);

  const isPromoInvalid =
    promoCode.length > 0 && promoCode.toLowerCase() !== "staff";

  return (
    <Grid item xs={12} sm lg sx={{ height: "30rem", mt: { xs: 2, sm: 0 } }}>
      <div>
        <Typography fontWeight={"bold"} fontSize={"1.3rem"}>
          Appointment Info
        </Typography>
        <Box mt={2}>
          <Grid container>
            <Grid item xs={5.5}>
              <Typography fontWeight={"medium"}>Ref No</Typography>
              <Typography>{sessionId} </Typography>
            </Grid>
            <Grid item xs>
              <Typography fontWeight={"bold"}>Appointment Date</Typography>
              <Typography>
                {getOriginalDateTime(appointmentDateTime).toLocaleDateString()}
              </Typography>
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid item fontWeight={"bold"} xs={5.5}>
              <Typography fontWeight={"bold"}>Patient Name</Typography>
              <Typography>{patientName}</Typography>
            </Grid>
            <Grid item xs>
              <Typography fontWeight={"bold"}>Appointment Time</Typography>
              <Typography>
                {format(getOriginalDateTime(appointmentDateTime), "p")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </div>

      <Grid container mt={2}>
        {patientMobileNo.length > 0 && (
          <Grid item xs={5.5}>
            <Typography fontWeight={"bold"}>Mobile No</Typography>
            <Typography>{patientMobileNo}</Typography>
          </Grid>
        )}

        {patientEmail ? (
          <Grid item xs>
            <Typography fontWeight={"bold"}>Email Address</Typography>
            <Typography>{patientEmail}</Typography>
          </Grid>
        ) : null}
      </Grid>
      <Divider
        orientation="horizontal"
        sx={{
          backgroundColor: "#ABABAB",
          mt: 2,
          mb: 2,
        }}
      />

      <Grid container mt={2} gap={2}>
        <Grid item xs>
          <Typography fontWeight={"bold"}>Amount </Typography>
          <StyledTextField
            readOnly
            label=" "
            fullWidth
            variant="standard"
            sx={{
              mt: 0,
            }}
            inputProps={{ readOnly: true }}
            value={
              isOtherCountry
                ? `${process.env.REACT_APP_CURRENCY} 10.00`
                : convertToCurrency({
                    amount:
                      parseFloat(
                        doctor.doctorFee + doctor.hospitalFee + doctor.otherFee
                      ) * conversionRatio,
                    currency: process.env.REACT_APP_CURRENCY,
                  })
            }
          />

          <Typography></Typography>
        </Grid>

        <Grid item xs>
          <Typography fontWeight={"bold"}>
            Promo Code
            {isPromoInvalid && (
              <span
                style={{
                  color: "red",
                  fontSize: "10px",
                }}
              >
                {" "}
                invalid code
              </span>
            )}
          </Typography>
          <TextField
            sx={{
              mt: 1,
            }}
            variant="standard"
            onChange={(event) => {
              const value = event.target.value;
              setPromoCode(value);
            }}
            value={promoCode}
            fullWidth
            size="small"
            error={isPromoInvalid}
          />
        </Grid>
      </Grid>

      <Grid container mt={4} sx={{ visibility: "hidden" }}>
        <RadioGroup row defaultValue={PAYMENT_METHODS.PAY_NOW}>
          <FormControlLabel
            value={PAYMENT_METHODS.PAY_NOW}
            control={
              <Radio
                onClick={() => {
                  setPaymentOption(PAYMENT_METHODS.PAY_NOW);
                }}
                disabled={!isPromoInvalid && promoCode.length > 0}
              />
            }
            label="Pay online"
          />
          <FormControlLabel
            value={PAYMENT_METHODS.CASH_PAYMENT}
            control={
              <Radio
                onClick={() => {
                  setPaymentOption(PAYMENT_METHODS.CASH_PAYMENT);
                }}
                disabled={!isPromoInvalid && promoCode.length > 0}
              />
            }
            label="Pay at hospital"
          />
          <FormControlLabel
            sx={{
              display: "none",
            }}
            value={PAYMENT_METHODS.PAY_LATER}
            title={
              sessionType === 1 || sessionType === 3
                ? "Pay later is not available for video consultations"
                : ""
            } // 1 is video call and 3 is hybrid
            disabled={sessionType === 1 ? true : false} // 1 is video call
            control={
              <Radio
                onClick={() => {
                  setPaymentOption(PAYMENT_METHODS.PAY_LATER);
                }}
              />
            }
            label="Pay Later"
          />
        </RadioGroup>
      </Grid>
      <Typography sx={{ my: 1 }}>
        By clicking complete, you agree to our{" "}
        <LinkText
          text={"terms and conditions"}
          onClick={() => {
            setShowing(1);
            setSideMenuOpen(true);
          }}
        />
      </Typography>

      <Box mt={2} display="flex" gap={2}>
        <Button
          variant="contained"
          disabled={isPromoInvalid}
          onClick={() => {
            setPaymentStatus({
              isVisible: true,
              content: "loading",
            });

            if (
              paymentOption === PAYMENT_METHODS.PAY_NOW &&
              promoCode.toLowerCase() !== "staff" &&
              !isFree
            ) {
              // dispatch(
              //   setAppointmentId({
              //     appointmentId: data.Id,
              //   })
              // );
              order(
                doctorId,
                channelingFee,
                isOtherCountry,
                patientEmail,
                patientMobileNo,
                patientId,
                (data) => {
                  let payUrl = data?.data?.data?.payUrl;
                  if (payUrl) {
                    let reference = data.reference;
                    let trnId = payUrl.substring(payUrl.indexOf("=") + 1);
                    setPaymentStatus({
                      isVisible: false,
                      content: "",
                    });
                    dispatch(
                      setPaymentDetails({
                        txnId: trnId,
                        reference: reference,
                      })
                    );
                    window.location.replace(payUrl);
                  } else {
                    setPaymentStatus({
                      isVisible: true,
                      content: "failed",
                    });
                  }
                },
                () => {
                  //TODO: handle error
                  setPaymentStatus({
                    isVisible: true,
                    content: "failed",
                  });
                }
              );
            } else if (
              paymentOption === PAYMENT_METHODS.CASH_PAYMENT &&
              promoCode.toLowerCase() !== "staff" &&
              !isFree
            ) {
              setPaymentStatus({
                isVisible: false,
                content: "",
              });
              makeAppontment(PAYMENT_METHODS.CASH_PAYMENT);
            } else {
              setPaymentStatus({
                isVisible: false,
                content: "",
              });
              makeAppontment(PAYMENT_METHODS.PROMO);
            }
          }}
        >
          Continue{" "}
          {loadingStatus.placeAppointment && (
            <CircularProgress
              size={15}
              sx={{
                ml: 1,
                color: "white",
              }}
            />
          )}
        </Button>
      </Box>
      <CustomModal open={showPopup}>
        <ShowAlert
          onClick={() => {
            setShowPopup(false);
            // setPaymentStatus({ isVisible: false, content: "" });
            // dispatch(
            //     setStep({
            //         step: 0,
            //     })
            // );
            // navigate(`/status?appointmentId=${appointmentId}`);
          }}
        />
      </CustomModal>
    </Grid>
  );
};

export default StepFour;
