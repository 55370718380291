import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import docotorImg from "../../assets/doctor.jpg";
import { useState } from "react";
import { useMemo } from "react";
import { convertToCurrency } from "../../utils/convert-to-currency";
import { useSelector } from "react-redux";

const DoctorDetails = ({
  doctor,
  doctorName,
  doctorId,
  qualification,
  regtistrationNo,
  languages,
  email = "",
  isOtherCountry,
  action = () => {},
}) => {
  console.log(doctor);
  const conversionRatio = useSelector(
    (state) => state.conversionRatio.conversionRatio
  );
  const [noImage, setNoImage] = useState(false);

  const doctorFee = isOtherCountry
    ? `${process.env.REACT_APP_CURRENCY} ` +
      process.env.REACT_APP_CHANNELING_FEE_OTHER +
      ".00"
    : convertToCurrency({
        amount: parseFloat(doctor.doctorFee) * conversionRatio ,
        currency: process.env.REACT_APP_CURRENCY,
      });

  const hospitalFee = isOtherCountry
    ? `${process.env.REACT_APP_CURRENCY} ` +
      process.env.REACT_APP_CHANNELING_FEE_OTHER +
      ".00"
    : convertToCurrency({
        amount: parseFloat(doctor.hospitalFee) * conversionRatio,
        currency: process.env.REACT_APP_CURRENCY,
      });

  const feeService = isOtherCountry
    ? `${process.env.REACT_APP_CURRENCY} ` +
      process.env.REACT_APP_SERVICE_FEE_OTHER +
      ".00"
    : convertToCurrency({
        amount: parseFloat(doctor.otherFee) * conversionRatio,
        currency: process.env.REACT_APP_CURRENCY,
      });

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const image = useMemo(
    () =>
      `${process.env.REACT_APP_VC_DOMAIN}${process.env.REACT_APP_IMAGE_PATH}${doctorId}.png`,
    [doctorId]
  );

  return (
    <div>
      {/* load image to see whether image is available. loading over network throws cors */}
      <img
        src={image}
        alt=""
        onError={(e) => {
          setNoImage(true);
        }}
        onLoad={(e) => {
          setNoImage(false);
        }}
        style={{
          display: "none",
        }}
      />
      <Box
        sx={{
          p: { xs: 0, sm: 2, lg: 2 },
          mt: { xs: 2, sm: 0, lg: 0 },
        }}
      >
        <Grid container gap={1} mb={2}>
          <Grid
            item
            display="flex"
            sx={{
              backgroundImage: `url(${noImage ? docotorImg : image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              height: {
                xs: "7rem",
              },
              width: {
                xs: "7rem",
              },
            }}
          >
            {/* <img
              src={image}
              alt=""
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = docotorImg;
              }}
              style={{
                height: "100%",
              }}
            /> */}
          </Grid>
          <Grid item xs>
            <Typography fontWeight={"bold"} fontSize="1.2rem">
              {doctorName}
            </Typography>
            <Typography fontSize="0.8rem">
              {doctor?.instituteBranchName}
            </Typography>
            <Typography fontSize="0.8rem">
              {qualification}
              <br />
              SLMC REG No :{regtistrationNo}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          mb={1}
          mt={4}
          sx={{
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Grid item xs={6}>
            <Typography fontWeight={"bold"}> Consultations In </Typography>
            <Typography style={{ color: "blue" }}> {languages} </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              mt: { xs: 2, md: 0 },
            }}
          >
            <Typography fontWeight={"bold"}> Contact via </Typography>
            <Typography
              {...(email.length > 20 && {
                title: email,
              })}
            >
              <a href={`mailto:${email}`} style={{ color: "blue" }}>
                {email.length > 20 && isDesktop
                  ? email.substring(0, 20) + "..."
                  : email.length > 40
                  ? email.substring(0, 40) + "..."
                  : email}
              </a>
            </Typography>
          </Grid>
        </Grid>

        <Box sx={{ marginBottom: 2 }} mt={3}>
          <Typography fontWeight={"bold"}> Consultation Fee </Typography>

          {doctor?.doctorFee !== 0 && (
            <Grid container>
              <Grid item xs={4}>
                Doctor fee
              </Grid>
              <Grid item> : {doctorFee}</Grid>
            </Grid>
          )}

          {doctor?.hospitalFee !== 0 && (
            <Grid container>
              <Grid item xs={4}>
                Hospital Fee
              </Grid>
              <Grid item> : {hospitalFee}</Grid>
            </Grid>
          )}

           {doctor?.otherFee !== 0 && (
            <Grid container>
              <Grid item xs={4}>
                Service Fee
              </Grid>
              <Grid item> : {feeService}</Grid>
            </Grid>
          )} 
        </Box>
      </Box>
    </div>
  );
};

export default DoctorDetails;
